<template>
    <Dialog header="Pending Requests" v-model:visible="display" :modal="true" :closable="true" class="group-requests-modal">
        <PendingRequestList v-if="selectedGroup.isPrivate" />
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import PendingRequestList from '../PendingRequestList.vue';

// import EventBus from '../../../event-bus';

export default {
    name: 'GroupRequestsModal',
    components: {
        Dialog,
        PendingRequestList,
    },
    props: {
        selectedGroup: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            display: false,
        };
    },

    methods: {
        open() {
            this.display = true;
        },
    },
};
</script>

<style>
.group-requests-modal {
    font-family: 'Trebuchet MS', 'Verdana';
    width: 600px;
    height: 580px;
    border-radius: 16px;
}

.group-requests-modal .p-dialog-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.group-requests-modal .p-dialog-content {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
    padding: 0px;
    height: 100%;
}
</style>
<style scoped>

</style>