<template>
    <div class="main-container">
        <LoadingSpinner :showLoading="initLoading" />
        <template v-if="memberRequests.length > 0 && !initLoading">
            <infinite-scroll @infinite-scroll="loadMoreRequests" message="" :noResult="noMoreRequests" :style="scrollStyle">
                <UserListItem :user="request.analyst" v-for="request in memberRequests" :key="request.analyst.analystId">
                    <template #actions>
                         <Button class="accept-request-button" @click="acceptRequest(request)">
                            <i class="pi pi-check" v-if="browserUtils.isMobile()" />
                            <span v-else>Accept</span>
                        </Button>

                        <Button class="decline-request-button" @click="declineRequest(request)">
                            <i class="pi pi-times" v-if="browserUtils.isMobile()" />
                            <span v-else>Decline</span>
                        </Button>
                    </template>
                </UserListItem>
                <LoadingSpinner :showLoading="loading" minHeight="100px" />
            </infinite-scroll>
        </template>
        <div class="no-requests-message" v-else-if="memberRequests.length === 0 && !initLoading">No pending requests</div>
    </div>
</template>

<script>
import LoadingSpinner from '../common/LoadingSpinner.vue';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import UserListItem from '../list/UserListItem.vue';

import GroupService from '../../service/GroupService';
import BrowserUtils from '../../utilities/BrowserUtils';
import { USER_TYPES } from '../../common/constants';

import EventBus from '../../event-bus';

export default {
    name: 'PendingRequestList',
    emits: ['request-accepted'],
    components: {
        InfiniteScroll,
        LoadingSpinner,
        UserListItem,
    },
    data() {
        return {
            //CONSTANTS
            USER_TYPES,

            initLoading: false,
            loading: false,
            memberRequests: [],
            noMoreRequests: false,

            browserUtils: BrowserUtils,
        };
    },
    computed: {
        scrollStyle() {
            return this.browserUtils.isMobile() ? 'width: 100%; height: calc(100vh - 220px);' : 'width: 100%; max-height: 100%; height: 520px;';
        },
    },

    mounted() {
        this.initLoading = true;
        this.loading = true;
        this.fetchRequests(10, 0);
    },

    methods: {
        fullName(analyst) {
            let full = analyst.firstName;
            if (analyst.middleName) {
                full += ' ' + analyst.middleName;
            }
            if (analyst.lastName) {
                full += ' ' + analyst.lastName;
            }
            if (full) {
                return full;
            } else {
                return '';
            }
        },
        acceptRequest(req) {
            GroupService.acceptJoinRequest(req.groupId, req.groupJoinRequestId).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: `Member request accepted!`, life: 2500 });
                    this.memberRequests = [];
                    this.fetchRequests(10, 0);
                    EventBus.emit('refresh-pending-requests');
                } else {
                    this.$toast.add({ severity: 'error', summary: `Accepting member request failed`, life: 2500 });
                }
            });
        },

        declineRequest(req) {
            GroupService.rejectJoinRequest(req.groupId, req.groupJoinRequestId).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: `Member request declined!`, life: 2500 });
                    this.memberRequests = [];
                    this.fetchRequests(10, 0);
                } else {
                    this.$toast.add({ severity: 'error', summary: `Declining member request failed`, life: 2500 });
                }
            });
        },
        async loadMoreRequests() {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreRequests) {
                    this.fetchRequests(this.memberRequests.length + 10, this.memberRequests.length);
                } else {
                    this.loading = false;
                }
            }
        },

        fetchRequests(limit, offset) {
            GroupService.getJoinRequests(this.$store.state.selectedGroup.groupId, limit, offset, null, true).then((resp) => {
                if (this.initLoading) {
                    this.initLoading = false;
                }

                this.loading = false;
                const requests = resp.data.requests;

                if (requests.length > 0) {
                    const results = this.memberRequests.concat(requests);

                    this.memberRequests = results;

                    this.noMoreRequests = false;
                } else {
                    this.noMoreRequests = true;
                }
            });
        },
    },
};
</script>

<style scoped>
.no-requests-message {
    font-size: 24px;
    font-weight: bold;
    color: #32364e;
    padding: 0px 16px 16px;
    text-align: center;
} 

.main-container {
    border-top: 1px solid #bfbfbf;
}

.buttons-container .p-button {
    border: 1px solid;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: #FFFFFF;
    color: #32364e;
}
.buttons-container .p-button:focus,
.buttons-container .p-button:hover {
    color: #FFFFFF;
}
.buttons-container .accept-request-button {
    border-color: #33CC99;
}
.buttons-container .accept-request-button:hover {
    background: #33CC99;
    border-color: #33CC99;
}
.buttons-container .accept-request-button:focus {
    box-shadow: none;
    background: #33CC99;
    border-color: #33CC99;
}
.buttons-container .decline-request-button {
    border-color: #E63E3E;
}
.buttons-container .decline-request-button:hover {
    background: #E63E3E;
    border-color: #E63E3E;
}
.buttons-container .decline-request-button:focus {
    box-shadow: none;
    border-color: #E63E3E;
    background: #E63E3E;
}
</style>